import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Tags from '../../components/Tags'

function TagsPage(props = {}) {
  const group = props.data.allMdx.group
  return (
    <Layout>
      <PageHeader title="Tags" />
      <Tags tags={group.map(g => g.fieldValue)} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`

export default TagsPage
